import * as Sentry from "@sentry/browser";
import { OrderTypeEnum } from "~/enums";

export function generateOrderNumber(
  orderId: number,
  orderType: "app" | "gp" = "app",
): string {
  let orderNumber: string = String(orderId);
  while (orderNumber.length < 6) {
    orderNumber = "0" + orderNumber;
  }
  const orderPrefix = orderType === "app" ? "ORD" : "GP";
  orderNumber = orderPrefix + orderNumber;
  return orderNumber;
}

export function parseOrderNumber(orderNumber: string): string | null {
  const match = orderNumber.match(/^([A-Z]+)(\d+)$/);
  if (!match) {
    Sentry.captureException(
      new Error(`Order with id ${orderNumber} is not valid order format`),
    );
    return null;
  }
  const [, orderType, orderIdStr] = match;
  const orderId = parseInt(orderIdStr, 10);
  if (isNaN(orderId)) {
    Sentry.captureException(
      new Error(
        `Order with id ${orderNumber} is not valid orderId: ${orderIdStr}`,
      ),
    );
    return null;
  }
  if (
    orderType.toLowerCase() !== OrderTypeEnum.GP &&
    orderType.toLowerCase() !== OrderTypeEnum.APP
  ) {
    Sentry.captureException(
      new Error(
        `Order with id ${orderNumber} is not valid order type: ${orderType}`,
      ),
    );
    return null;
  }

  return `${orderType.toLowerCase()}-${orderId}`;
}
